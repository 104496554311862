var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "footer-wrap"
  }, [_c('div', {
    staticClass: "footer-top-list",
    style: {
      'background-color': _vm.loginPage ? 'none' : '#00b1ff'
    }
  }, [_c('ul', {
    staticClass: "footer-top-cont"
  }, _vm._l(_vm.footerTopArr, function (item, index) {
    return _c('li', {
      key: index,
      staticClass: "item-style"
    }, [_c('img', {
      attrs: {
        "src": _vm.loginPage ? item.iconLogin : item.icon,
        "alt": ""
      }
    }), _c('div', {
      staticClass: "item-text",
      style: {
        color: _vm.loginPage ? '#666' : '#fff'
      }
    }, [_c('p', {
      staticClass: "item-tit"
    }, [_vm._v(_vm._s(item.title))]), _c('p', {
      staticClass: "item-sub"
    }, [_vm._v(_vm._s(item.subline))])])]);
  }), 0)]), _c('div', {
    staticClass: "footer-bot-wrap"
  }, [_c('p', [_vm._v("互联网药品信息服务资格证书:(渝)-经营性-2018-0002")]), _vm._m(0), _vm._m(1), _c('p', [_vm._v("重庆普健医药有限公司 "), _c('a', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isCurrentLogin,
      expression: "isCurrentLogin"
    }],
    staticClass: "text",
    attrs: {
      "href": "/about-us.html",
      "target": "_blank"
    }
  }, [_vm._v("关于我们")]), _c('a', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isCurrentLogin,
      expression: "!isCurrentLogin"
    }],
    staticClass: "text",
    staticStyle: {
      "cursor": "not-allowed"
    }
  }, [_vm._v("关于我们")])]), _vm._m(2), _c('div', {
    staticClass: "footer-bot-wrap-imgs"
  }, [_vm._m(3), _c('div', {
    staticClass: "footer-bot-wrap-img"
  }, [_c('img', {
    attrs: {
      "src": _vm.qrCodeUrl
    }
  }), _c('p', [_vm._v("下载普健医药APP")])])])])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_vm._v(" 渝ICP备"), _c('a', {
    staticClass: "text",
    attrs: {
      "href": "https://beian.miit.gov.cn/#/Integrated/index",
      "target": "_blank"
    }
  }, [_vm._v("18008873号-3")]), _vm._v("; 监管机构： 国家食品药品监管局 重庆市食品药品监管局51La ")]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('a', {
    staticStyle: {
      "display": "inline-block",
      "text-decoration": "none",
      "height": "20px",
      "line-height": "20px",
      "color": "#666"
    },
    attrs: {
      "target": "_blank",
      "href": "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010802004051"
    }
  }, [_c('img', {
    staticStyle: {
      "float": "left"
    },
    attrs: {
      "src": require("../../assets/images/20220526113111.png")
    }
  }), _vm._v("渝公网安备 50010802004051号")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('p', [_c('a', {
    staticStyle: {
      "text-decoration": "none"
    },
    attrs: {
      "id": "_xinchacharenzheng_cert_vip_ssl",
      "target": "_blank",
      "href": " "
    }
  }, [_c('img', {
    staticStyle: {
      "height": "50px",
      "width": "164px"
    },
    attrs: {
      "src": "https://xyt.xinchacha.com/img/icon/icon8.png"
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "footer-bot-wrap-img"
  }, [_c('img', {
    attrs: {
      "src": require("./images/gzh.jpg"),
      "alt": ""
    }
  }), _c('p', [_vm._v("关注普健医药公众号")])]);
}];
export { render, staticRenderFns };